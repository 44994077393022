import React from 'react';
import './Motivation.scss';
import { useSelector } from 'react-redux';
import { motion } from "framer-motion";

export default function Motivation() {

    const guest = useSelector(state => state.user.user)
   
  return (
    <div className='motivation-container'>
        <motion.h2 initial={{x: 310, opacity:0}} transition={{duration:1}} animate={{ x: 0, opacity:1 }} className='main-section-h2-title'>Lettre de motivation</motion.h2>
        <div className='divider'></div>
        <motion.div initial={{x: -310, opacity:0}} transition={{duration:1}} animate={{ x: 0, opacity:1 }} className='motivation-box'>
            <h2>{ guest.name ? guest.name : null},</h2>
            

            <p className='p-motive'>
                Actuellement à la recherche d'un emploi en tant que Développeur Front-End, je me permets de vous adresser ma candidature au sein de <span className='entreprise'> {guest.company ? guest.company : null} </span>. Passionné par le développement informatique, j'ai entrepris une reconversion professionnelle en 2016 après avoir obtenu mon BEP, BAC, et BTS dans le domaine de l'automobile, où je ne trouvais finalement plus l'épanouissement recherché.
            </p>
            
            <p className='p-motive'>
                Au cours de ma reconversion, j'ai acquis des compétences solides et variées aussi bien en autodidacte qu’au cours de formation et d’expériences professionnelles. J'ai suivi une formation intensive en autodidacte via Udemy, complétée par un cursus à la 3WACADEMY en tant que Développeur Intégrateur Web. Mon parcours s'est consolidé avec une formation de deux ans chez Open Classrooms, où j'ai obtenu le titre de Développeur Front-End.
            </p>

            <p className='p-motive'>
            Mon expérience professionnelle, notamment en alternance chez Madox XR à Paris, m'a permis de mettre en pratique mes compétences de manière concrète. J'ai participé à la création de pages sur Figma et à l'implémentation de maquettes en code (HTML, CSS, React). En tant que Développeur Front-End, j'ai également eu l'occasion de communiquer efficacement avec l'API de l'entreprise, de résoudre des problèmes techniques, et d'apporter des solutions à des défis présents sur des Todo Lists.
            </p>
            <p className='p-motive'>Mon engagement dans des projets chez Skyparts et MtecParis en tant que Développeur Web m'a permis de développer des compétences approfondies en responsive design, en intégration texte et image, ainsi qu'en assurant la compatibilité sur différents navigateurs.</p>
            <p className='p-motive'>Aujourd’hui, mon ambition est de rejoindre <span className='entreprise'>{guest.company ? guest.company : null}</span> en tant que Développeur Front-End pour continuer à acquérir de l'expérience précieuse et approfondir mes compétences. À court terme, je suis déterminé à m'investir pleinement dans mon rôle de développeur et à contribuer de manière significative à vos projets. À moyen terme, je prévois de me spécialiser davantage dans un langage en particulier, et à long terme, j'aspire à évoluer vers un rôle de chef de projet, mettant ainsi à profit mes compétences techniques, mon leadership et mon expérience.</p>
            <p className='p-motive'>
            Couplées à mes compétences techniques : ma rigueur, ma créativité, mon esprit orienté vers l’innovation et la nouveauté sont autant d'atouts que je suis convaincu de pouvoir mettre à profit au sein de <span className='entreprise' >{guest.company ? guest.company : null}</span>, je serais honoré de contribuer au succès de votre marque.
            </p>
            <p className='p-motive'>
                Je reste à votre disposition pour un entretien afin de discuter de ma candidature plus en détails.
            </p>

        </motion.div>
        

    </div>
  )
}
