import React from 'react';
import './Portfolio.scss';

export default function Portfolio() {
    const webSites = [
        {
            name : 'La pistoleterie',
            link: "https://lapistoleterie.netlify.app/",
            year: 2023,
            img: "./pistoleterie.png"
        },
        
       {
            name : 'MtecParis',
            link: "https://mtecparis.com/",
            year: 2020,
            img: "./mtec.png"
        },
        {
            name : 'Skypart',
            link: "https://skyparts.fr/",
            year: 2020,
            img: "./skyparts.png"
        }
    ]

    //  <a href=""></a> 
    return (
    <div className='portfolio-container'>
        <h2 className='main-section-h2-title'>Portfolio</h2>
        <div className='divider'></div>
        <div className='porfolio-section'>

        
            <div className='portfolio-box'>
                {
                    webSites.map( (website,index) =>{
                        return ( <a target='_blank' rel="noreferrer" href={website.link} key={index} className='item-portfolio'>
                        <h2 className='h2-item-portfolio'>{website.name}</h2>
                        <h3 className='year-h3'>{website.year}</h3>
                            <img src={website.img} className='img-porfolio' alt={'entreprise' + website.name} />
                        
                    </a>)
                    })
                
                }
                
            </div>
        </div>
    </div>
  )
}
