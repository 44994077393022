import React, { useState } from 'react'
import './Nav.scss';
import ToggleButton from './ToggleButton/ToggleButton';

export default function Nav() {

const [open, setOpen] = useState(false);

  return (
  
    <div className={open ? 'nav-container open':'nav-container' } >
        <ToggleButton setOpen={setOpen} open={open} />
        
        <ul className='nav-ul'>
            <li className='nav-li'  onClick={()=>setOpen(prev => !prev)}> <a href="#homepage">Homepage</a></li>
            <li className='nav-li' onClick={()=>setOpen(prev => !prev)}> <a href="#about">About</a></li>
            <li className='nav-li' onClick={()=>setOpen(prev => !prev)}> <a href="#skills">Skills</a></li>
            <li className='nav-li' onClick={()=>setOpen(prev => !prev)}> <a href="#portfolio">Portfolio</a></li>
            <li className='nav-li' onClick={()=>setOpen(prev => !prev)}> <a href="#contact">Contact</a></li>
        </ul>
        

        
    </div>
   
  )
}
