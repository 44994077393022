import React from 'react';
import './ToggleButton.scss';

export default function ToggleButton({setOpen,open}) {
    console.log(open)
  return (
    <div onClick={()=>setOpen(prev => !prev) } className='hamburger'>
            <div className='lane'></div>
            <div className='lane'></div>
            <div className='lane'></div>
        </div>
  )
}
