import React, { useRef, useState } from 'react';
import './Skills.scss';
import useSound from 'use-sound';
import shootSoundEffect from './shoot.mp3';
import reloada from './Reload.mp3'
import PopUp from '../PopUp/PopUp';
import { useInView } from 'framer-motion';
import { motion } from "framer-motion";

export default function Skills() {

    const stack=['HTML 5','CSS 3','JAVASCRIPT','TYPESCRIPT','REACT JS','BOOTSTRAP','REDUXTOOLKIT', 'SASS','NPM',"RESPONSIVE"]
    const  [newTabStack, setNewTabStack] = useState(stack);
    const langues =[
        {langue:'FRANÇAIS',niveau:'NATIF'},
        {langue:'ANGLAIS',niveau:'A2'}
    ]
    const graphics=['SONY VEGAS','FIGMA','ADOBE XD'];
    const [play] = useSound(shootSoundEffect); 
    const [reload] = useSound(reloada);
    const handleBoom = (e)=>{
        play()
        // e.target.remove();
        let arrayFilter = newTabStack.filter(stack => e.target.innerText !== stack )
        setNewTabStack(arrayFilter);
    
    }
    const handleReset = ()=>{
         setNewTabStack(stack);
         reload();
        
    }



const ref = useRef();
const variants = {
    initial:{
        x:0,
        y:150,
        
        opacity:0,
    },
    animate:{
        x:250,
        y:150,
        opacity:1,
        transition:{
            duration:1
        },
    },
}


const isInView = useInView(ref,{margin: "-100px"});

const [close, setClose] = useState(false)

  return (
    <div className='skills-main-container'>
        <h2 className='main-section-h2-title'>Stand de Skills</h2>
        <div className='divider'></div>
            <motion.div ref={ref} variants={variants} initial={"initial"} whileInView={isInView && "animate"}>
              { !close && <PopUp setClose={setClose} close={close} />}
               
            </motion.div>
           
           
        <div className='skills-stack-container'>
            <h2 className='skill-stack-title'>Stacks</h2>
        <div className='skills-stack-box'>

       
        {
            newTabStack.map((language,index) =>{
                return <h3 onClick={handleBoom} key={index} id={index} className='skill'>{language}</h3>
            })
        }
           {
            // si le tableau est inférieur a 9 c'est qu'on a tiré sur une cible alors on peut afficher le btn reset 
            newTabStack.length < 9 ? <button className='btn-reset' onClick={handleReset}>Reset Skills</button> : null
           }
             </div>
        </div>
        <div className='skills-container'>
            <h2 className='skills-title'>Languages</h2>
            <div className='skills-box'>
            {
                langues.map((langue,index) =>{
                    return <h3 key={index} className='skills-item'> {langue.langue} : {langue.niveau} </h3>
                })
            }
            </div>
        </div>
        <div className='skills-container'>
                <h2 className='skills-title'> Compétences Graphiques </h2>
                <div className='skills-box'>
                    {
                        graphics.map((graphic,index) =>{
                            return <h3 key={index} className='skills-item'>{graphic}</h3>
                        })
                    }
                </div>
        </div>
    </div>
  )
}
