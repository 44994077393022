import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import './Contact.scss';


export default function Contact() {
    
const form = useRef();
const [name, setName] = useState('')
const [email, setEmail] = useState('')
const [message, setMessage] = useState('')
let checkName = /^[a-zA-Z ]+$/.test(name);
let checkEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)
  
const [error, setError]= useState(false);
const [errorName, setErrorName]= useState('');
const [errorEmail, setErrorEmail]= useState('');
const [errorMessage, setErrorMessage]= useState('');


const handleSubmit = (e)=>{
e.preventDefault();


if(!checkName || name.length < 3){
    setError(true);
    setErrorName('Le nom doit contenir uniquement des lettre et plus de 3 caractères ')
    return false
}else if(!checkEmail){
    setErrorName('')
    setError(true);
    setErrorEmail("L'email n'est pas valide");
    return false

  
}else if(message.length < 20){
    setErrorEmail('')
    setError(true)
    setErrorMessage("Le message doit contenir plus de 20 caractères")
    return false
}else{
    setErrorMessage('');
    setError(false);
    
}


emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current, process.env.REACT_APP_PUBLIC_KEY)
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

document.getElementById('form').reset()
// console.log(email);
// console.log(message);
}
    return (
    <div className='contact-container'>
        <h2 className='main-section-h2-title'>Contact</h2>
        <div className='divider'></div>
        <div className='contact-box'>
            <div className='part-one'>
                <h2 className='work-h2'>Let's work together </h2>
                <div className='div-contact-item'>
                    <h3 className='title-contact'>Mail</h3>
                    <a href='mailto:gloucedric@gmail.com' className='p-contact'>gloucedric@gmail.com</a>
                </div>
                <div className='div-contact-item'>
                    <h3 className='title-contact'>Localisation</h3>
                    <p className='p-contact'>Paris</p>
                </div>
                <div className='div-contact-item'>
                    <h3 className='title-contact'>Téléphone</h3>
                    <a href='tel:0768211650' className='p-contact'>07.68.21.16.50</a>
                </div>

            </div>

            <div className='part-two'>
                <form ref={form} id='form' onSubmit={handleSubmit}>
                    <input className='input-form' id='firstname' name='firstname' placeholder='Name' onChange={(e)=>setName(e.target.value)} type="text" />
                    {
                        error && <p className='p-error'>{errorName}</p> 

                    }
                    <input className='input-form' placeholder='Email' name='email' onChange={(e)=>setEmail(e.target.value)} type="text" />
                    {
                         error && <p className='p-error'>{errorEmail}</p> 
                    }
                    <textarea className='input-form' name="message" id="" onChange={(e)=>setMessage(e.target.value)} cols="30" placeholder='Message' rows="10"></textarea>
                     {
                         error && <p className='p-error'>{errorMessage}</p> 
                    }
                    <input className='button-submit' type="submit" value="Envoyer"/>
                </form>
            </div>
        </div>
    </div>
  )
}
