import "./App.scss";
import Nav from "./components/Nav/Nav";
import Header from "./components/Header/Header";
import Motivation from "./components/Motivation/Motivation";
import Skills from "./components/Skills/Skills";
import Contact from "./components/Contact/Contact";
import Portfolio from "./components/Portfolio/Portfolio";
import QuestionOne from "./components/QuestionOne/QuestionOne";
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useSelector } from "react-redux";

function App() {
  ReactGA.initialize(process.env.REACT_APP_TRACK_ID_GA4);
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname + window.location.search,
    title: "HomePage",
  });
  console.log();
  const [showQuestion, setShowQuestion] = useState(false);

  const guest = useSelector((state) => state.user.user);
  console.log(guest.guest);

  useEffect(() => {
    // imposibilité de scroller tant que le guest n'a pas répondu aux question
    if (!showQuestion) {
      document.body.classList.add("freez");
    } else {
      document.body.classList.remove("freez");
    }
  }, [showQuestion]);
  return (
    <div className="App">
      <Nav />
      {!showQuestion && <QuestionOne setShowQuestion={setShowQuestion} />}
      <section id="homepage">
        <Header />
      </section>

      {guest.guest === "oui" ? null : (
        <section id="about">
          <Motivation />
        </section>
      )}
      <section id="skills">
        <Skills />
      </section>

      <section id="portfolio">
        <Portfolio />
      </section>
      <section id="contact">
        <Contact />
      </section>
    </div>
  );
}

export default App;
