import React from 'react';
import './Header.scss';



export default function Header() {
  return (
    <div className='hero-conatiner'>
        <div className='hero'>
            <div className='wrapper'>
                <div className="text-container"  initial='initial' animate='animate'>
                    <h2>Cedric.G <a target='_blank' rel="noreferrer" href="https://github.com/Ced933"><i className="fa-brands fa-github"></i></a></h2>
                    <h1>Developpeur Front-End</h1>
                    <div className="buttons" >
                        <a href="#portfolio" className='button-header'>Projets récents</a>
                        <a href="#contact" className='button-header'>Me contacter</a>
                    </div>
                    {/* <img src="/scroll.png" alt="" /> */}
                </div>
            </div>
            {/* <div className='sliding-texte-container'>
                Welcome to my resume
            </div> */}
            <div className='image-container'>
                <img src="/bendy-man-developer-writing-programming-code.gif" alt="cedric" />
            </div>
        </div>
    </div>
  )
}
