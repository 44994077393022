import React from 'react';
import './PopUp.scss';

export default function PopUp({setClose,close}) {
  return (
    <div className='pop-up'>
                    <img className='cross' src="./delete.png" alt="" onClick={()=>setClose(prev => !prev)} />
                <div className='pop-up-container'>
                    <h3>Chill Zone 🎯</h3>
                    <p>Vous pouvez vous relaxer en tirant 🔫 sur les noms des stacks et reset les cibles autant de fois que vous voulez </p>

                </div>
            </div>
  )
}
