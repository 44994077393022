import React, { useState } from 'react';
import './QuestionOne.scss';
import { useDispatch, useSelector } from 'react-redux';

export default function QuestionOne({setShowQuestion}) {
    // entreprise autorisées 
    const companyAllow = ['eyetech solutions', 'le collectionist', 'axelor', 'jarvis legal', 'extia','mobiappsfr','urban linker', 'astek','alten', 'renewex', 'koala interactive','klee group','quick interim','elcimaï','twenty one talents','sopra steria','its group','staffmatch','onlieffe','edf','confidentiel', 'hager group'];
    const drGero = ['c-16', 'c-17', 'c-18','C-16', 'C-17', 'C-18']
    const [drGeroNames, setDrGeroNames] = useState(false);

    // grâce à dispatch on va pouvoir envoyer nos donnes dans le store 
    const dispatch = useDispatch();
    // avec hour on va récupérer l'heure actuelle pour savoir si on dit Bonjour ou Bonsoir on dira bonsoir qu'a partir de 17h  
    let date = new Date();
    let hour = date.toLocaleTimeString().slice(0,2)
    // on transforme hour en number pour pouvoir le comparer car il est en string a la base 
    let numberHour = parseInt(hour);
    // ai je repondu a la 1er question si oui je peux passer à la prochaine 
    const [didIrespondToTheFirstQUestion, setDidIrespondToTheFirstQUestion] = useState(false);
    // on récupère nos données dans le store 
    const user = useSelector(state => state.user.user);
    console.log(user)
    // prenom de l'invité 
    const [nameGuest, setNameGuest] = useState('');

    const [errorNameGuest, setErrorNameGuest] = useState(false)
    // nom de sa company 
    const [companyName, setCompanyName] = useState('');
    const [errorGuestCompany, setErrorGuestCompany] = useState(false);

    const [guest, setGuest] = useState(false);
    console.log(guest)
    let checkName = /^[a-zA-Zéï\-/ ]+$/.test(nameGuest);

    let errorMessageCompany  = "Désolé je n'ai pas le souvenir d'avoir postulé dans cette entreprise 😕";

    const handleGuest = (e)=>{
        e.preventDefault()
        setGuest(prev => !prev)
        setTimeout(()=>{
            // au bout 1s le popup disparait car il faut laisse le temps au formulaire de submit avant de disparaitre 
            setShowQuestion(prev => !prev)
        },1000)
        dispatch({
            type:"user/addName",
            payload:{
                name: user.name,
                guest : "oui"
            }
        })
    }

    const handleSubmitName = (e)=>{
        e.preventDefault();
        if(drGero.includes(nameGuest)){
            setDrGeroNames(true)
             return false 
 
         }

        else if(!checkName){
            setErrorNameGuest(true)
            return false
        }
        else{
            setDrGeroNames(false)
            setErrorNameGuest(false)
            let capitalizeFirstLetter = nameGuest[0].toUpperCase() + nameGuest.slice(1)
            console.log(capitalizeFirstLetter);
    
            dispatch({
                type:"user/addName",
                payload:{
                    name : capitalizeFirstLetter,
                }
            })
            // réinitialiser le formulaire 
            document.getElementById('form').reset();
            setDidIrespondToTheFirstQUestion(true)
        }
    }
    const handleSubmitcompany = (e)=>{
        e.preventDefault();
        let comanyNamelowercase = companyName.toLowerCase();
        if(companyAllow.includes(comanyNamelowercase)){

            
            setErrorGuestCompany(false);
            document.getElementById('form').reset();
            dispatch({
                type:"user/addName",
                payload:{
                    name: user.name,
                    company : comanyNamelowercase[0].toUpperCase() + comanyNamelowercase.slice(1)
                }
            })

            setTimeout(()=>{
                // au bout 1s le popup disparait car il faut laisse le temps au formulaire de submit avant de disparaitre 
                setShowQuestion(prev => !prev)
            },1000)
        
        }else{
            setErrorGuestCompany(true)
            return false 
        }
    }


  return (
    <div  className='container-question'>

        <div className='bg-question'>
            {

            didIrespondToTheFirstQUestion ? 
            (<form id='form' className='form'  onSubmit={handleSubmitcompany}>
                <h3 className='h3-question'> Enchanté {user.name}, quelle est le nom de votre entreprise ? </h3>
                <div className='div-btn-question'>
                    <input className='input-name' type="text" onChange={(e)=>setCompanyName(e.target.value)} placeholder='Votre entreprise' />
                    {
                        errorGuestCompany && <p className='p-error-question'> {errorMessageCompany}</p>
                    }
                  
                         <input className='btn-name'  type="submit" value="Valider" />

                         <a onClick={(e)=>{handleGuest(e)}} className='link-invite' href="#">Je suis ici par curiosité 🫣</a>
                     
                </div>
            </form>) 
            :
            (<form id='form' className='form' onSubmit={handleSubmitName}>
                <h3 className='h3-question'> { numberHour > 17 ? " Bonsoir" : "Bonjour"}, <br /> Bienvenue sur mon cv en ligne. <br /> Je m'appelle Cedric et vous ?</h3>
                <div className='div-btn-question'>
                    <input className='input-name' type="text" onInput={(e)=>setNameGuest(e.target.value)} placeholder='Votre prenom' />
                    {
                        errorNameGuest &&  <p className='p-error-question'>Aucun chiffre ou caractère spécial n'est accépté, seule les prénoms c-17, c-18 et c-16 sont autorisés</p>
                    }
                    {
                        drGeroNames &&  <p className='p-error-question'>C'etait une blague voyons 😂</p>
                    }
                    <input className='btn-name' type="submit" value="Enchanté(e)" />
                </div>
            </form>) 
                }


</div>

    </div>
  )
}
